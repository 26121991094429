* {
  padding: 0;
}
.bg_wrapper {
  background-image: linear-gradient(90deg, #ebfffe 30%, #ffffff 100%);
}
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0ce7dd;
}
.service_section_text {
  margin-bottom: 60px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0ce7dd;
}
.listing_products {
  padding-bottom: 100px;
}
.input_component {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 40px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: white;
  border-color: #e5e7eb;
  border-radius: 6px !important;
  border-style: solid;
  border-width: 3px !important;
  box-shadow: 0 !important;
  box-sizing: border-box;
  height: 55px;
  padding-left: 8px;
  color: #333230 !important;
  width: 100%;
  padding-left: 24px !important;
  font-size: 14px !important;
  margin-bottom: 10px;
}
.form-control.is-invalid {
  border-color: red !important;
  background-image: none !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.p_star {
  margin-bottom: 10px;
}
.error_styles {
  border-color: red !important;
  border-width: 1px !important;
}
.checkbox_container {
  display: flex;
}
.checkbox_style {
  width: fit-content;
}
.single-features {
  padding: 0;
  height: 100%;
  position: relative;
  cursor: pointer;
}
.single-features .f-icon {
  margin-bottom: 0;
}
.single-features .f-icon img {
  border-radius: 10px;
}
.single-features .overlay {
  bottom: 0;
  height: 100%;
  border-radius: 10px;
  height: 100%;
  top: 0;
  max-height: 300px;
}
.overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}
.about_container {
  padding-top: 100px;
  @media(max-width:767px){
    padding-top: 50px;
  }
}
.why-us-box {
  /* height: 160px; */
  margin-bottom: 20px;
}
.custom-list {
  -webkit-column-count: 2; /* Chrome/Opera, Safari */
  -moz-column-count: 2; /* Mozilla Firefox */
  column-count: 2;

  /* Properties below are optional: */
  -webkit-column-gap: 50px; /* Chrome/Opera, Safari */
  -moz-column-gap: 50px; /* Mozilla Firefox */
  column-gap: 50px;
}
.font_poppins {
  font-family: "Poppins", sans-serif;
  color: #22222287;
  font-weight: 400;
  font-size: 15px !important;
}
.text-box {
  white-space: nowrap;
  color: white;
  font-size: 20px;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.text-box h6 {
  /* height: 100px; */
}
.features-inner {
  /* box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); */
  padding: 0px 0;
}
.single_feature_wrapper {
  width: 23%;
  margin-right: 2%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 40px 0;
}
.product-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.order_box .creat_account label {
  padding-right: 5px;
}
.hover {
  cursor: pointer;
}
.disabled-btn {
  pointer-events: none;
  opacity: 0.8;
}
.border-none {
  border: none;
}
.pointer-none {
  cursor: default;
}
ul.cb-list li {
  font-size: 14px;
  color: #777777;
  font-weight: normal;
  border-bottom: 1px solid #eeeeee;
  display: block;
  line-height: 42px;
  display: flex;
  justify-content: space-between;
}

.bcb-head li {
  font-weight: 700;
}
.card {
  background-color: #fff !important;
  border-top: none !important;
  border-bottom: none !important;
}
.card-header {
  background-color: #fff !important;
  border: none !important;
}
.card-footer {
  background: #fff !important;
  border: none !important;
}
.pagination {
  float: right;
}
.card-custom th,
.card-custom td,
.card-custom tr {
  font-family: "Poppins";
  vertical-align: middle;
  text-align: center;
}
.badge {
  padding: 10px;
}
.table thead {
  border-bottom: 1px solid #dee2e6;
}
.bg-success {
  background: rgb(85 247 172) !important;
  color: rgb(6 81 46) !important;
}
.bg-error {
  background: rgb(255, 93, 93) !important;
  color: rgb(136, 0, 0) !important;
}
.bg-warning {
  background: rgb(255, 226, 141) !important;
  color: rgb(189, 142, 0) !important;
}
.organic-breadcrumb {
  background-image: none !important;
  background-color: #e9eaff;
}
.organic-breadcrumb h1,
.organic-breadcrumb a {
  color: #0ce7dd !important;
}
.search-icon {
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translate(0%, -50%);
  z-index: 30;
  padding-left: 20px;
}
.cart_area {
  padding-top: 50px;
  padding-bottom: 100px;
}
.cart_area {
  padding-bottom: 100px;
  padding-top: 0px;
  margin-top: -33px;
  z-index: 1;
  position: relative;
}
.titleText {
  z-index: 2;
  position: relative;
  @media (max-width: 767px) {
    margin-bottom: 30px !important;
    margin-top: 50px !important;
  }
}
.search-input {
  padding: 15px 50px;
  border-radius: 10px !important;
  /* padding: 10px 50px 10px 50px; */
}
.custom-table {
  margin-top: 50px;
  /* border-top: 1px solid #dee2e6; */
}
.poppins {
  font-family: "Poppins", sans-serif !important;
}
.single-slide {
  height: 500px;
}
.order-link:hover {
  color: #0ce7dd;
  text-decoration: underline;
}
.service__item i {
  color: #0ce7dd;
  font-size: 50px;
}
.max_800 {
  max-width: 800px;
  @media (max-width:767px){
  padding:0  20px ;
  max-width: 95%;
  
  }
}
.text_section_wrapper {
  padding: 45px 0;
  @media (max-width:767px){
    padding: 45px 10px;
  
  }
}
.text-bg {
  background-color: white;
  background-image: url(https://marinio.peerduck.com/wp-content/uploads/2022/12/hgvjhv.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.text_section_wrapper h1 {
  color: #0ce7dd;
  font-weight: 500;
}
.hero_content img,
.common_header img {
  max-width: 60px;
  margin-bottom: 20px;
}
.hero_content h1 {
  color: #ffffff;
  font-family: "Roboto", Sans-serif;
  font-size: 56px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.25em;
  letter-spacing: 6px;
  @media (max-width:767px){
    font-size: 34px;
  }
}
.common_header {
  max-width: 562px;
}
.common_header h1 {
  color: #1a1a1a;
  font-family: "Poppins", Sans-serif;
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.25em;
  letter-spacing: 6px;
}
h1.page-heading {
  color: #1a1a1a;
  font-family: "Poppins", Sans-serif;
  font-size: 25px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.25em;
  letter-spacing: 6px;
  @media (max-width:767px){
    margin-top: 40px;
  }
}
.common_header p {
  text-align: center;
  color: #535353;
  font-family: "Asap", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6em;
  letter-spacing: 0.2px;
}
.page-link:focus {
  color: #0ce7dd;
  box-shadow: none;
}
.form-control:focus {
  box-shadow: none;
  border-color: #222222;
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: none;
}
.hero_content h4 {
  color: #ffffff;
  font-family: "Roboto", Sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 6px;
}
.hero_content .hero_btn {
  font-family: "Asap", Sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-image: url(https://marinio.peerduck.com/wp-content/uploads/2021/10/but0bhf.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2px;
  color: white;
  padding: 12px 20px;
  margin-top: 15px;
}

.custom_btn {
  font-family: "Asap", Sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  background-image: url(https://marinio.peerduck.com/wp-content/uploads/2021/10/btn-bg-323.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 60px;
  color: white;
  padding: 12px 20px;
  margin-top: 15px;
}

.hero_content .hero_btn:hover,
.custom_btn:hover {
  background-color: #7a7a7a;
  background-image: url(https://marinio.peerduck.com/wp-content/uploads/2021/10/bg-whitejlk.png);
  color: #222222;
}
.logo_h {
  width: 30px;
}
.header_area .main_menu .main_box {
  padding: 20px 0;
}
#banner-text-bg {
  z-index: -1;
  height: 100%;
  max-height: 370px;
  width: 100%;
  height: 100%;
}
.breadcrumb-banner {
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.breadcrumb-banner .col-first{
  @media (max-width:767px) {
    padding-right: 0;
  }
}
.organic-breadcrumb {
  background-color: #ebfbff;
}
.organic-breadcrumb h1,
.organic-breadcrumb a {
  color: #0a2440 !important;
}
.banner-area {
  overflow: hidden;
}

/* Service Card */
.service_card_container {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 20px 20px 20px 20px;
  --e-column-margin-right: 20px;
  --e-column-margin-left: 20px;
  padding: 35px 35px 35px 35px;
  background-color: white;
  box-shadow: 0 8px 40px -5px rgba(9, 31, 67, 0.1);
}
.service_card_container img {
  max-width: 42px;
  margin: 0px 0px 15px 0px;
  padding: 0px 0px 0px 0px;
}

.service_card_container h6 {
  color: #1a1a1a;
  font-family: "Roboto", Sans-serif;
  font-size: 28px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.25em;
  letter-spacing: 4px;
  margin-bottom: 30px;
}

.service_card_container h5 {
  color: #5379fe;
  font-family: "Roboto", Sans-serif;
  font-size: 22px;
  font-weight: 800;
  padding: 0px 0px 0px 0px;
  margin-bottom: 30px;
}
.service_card_container h5 span {
  color: #1a1a1a;
  font-size: 20px;
  font-weight: 400;
}
#site-footer {
  z-index: 1;
  /* overflow: hidden; */
  @media (max-width: 767px) {
    overflow: hidden;
  }
}
#footer-wave {
  vertical-align: middle;
}
.footer-area {
  background-color: #ebfbff;
  padding: 50px;
  @media (max-width: 767px) {
    padding: 180px 50px;
  }
}
.footer-inside {
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0%);
  @media (max-width: 767px) {
    left: 52%;
    background: #ebfbff;
    overflow: hidden;
  }
}
.footer_content_wrapper {
  max-width: 800px;
  @media (max-width: 767px) {
    padding: 0 20px!important;
    /* padding-bottom: 0!important; */
  }
}
.footer-area a {
  color: #5379fe;
}
.footer-area a:hover {
  color: #222222;
}
.login_section {
  padding: 120px 0 50px;
}
.login_form_inner {
  @media (max-width: 767px) {
    padding-top: 115px;
    padding-bottom: 115px;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }
}
.button_login {
  padding-top: 50px;
}
.mobile_only {
  display: none;
  @media (max-width: 767px) {
    display: block;
  }
}
.desktop_only {
  display: block;
  @media (max-width: 767px) {
    display: none;
  }
}
.login_box_area .login_box_img{
  @media (max-width:767px) {
    margin-right: 0;
  }
}
.login-container{
  @media (max-width:767px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.contact_wrapper{
  height: 300px;
  max-width: 800px;
  @media (max-width:767px) {
    height: 300px;
    max-width: 800px;
  }
}
.contact_box{
  /* height:100%; */
  /* width: 100%; */
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
  @media (max-width:767px) {
  width: 100%;
  padding: 0 30px;
  }
}
.contact_box h1{
  @media (max-width:767px) {
  font-size: 1.75rem;
  }
}
.text-banner-bg{
  @media (max-width:767px) {
  height: 180px!important;
  }
}
.order_download_btn{
  border-radius: 0;
  padding: 0 20px;
  line-height: 40px;
  top: 0 ;
  right: 0;
  @media (max-width:767px) {

  top: 100px;
    right: 20px;
  }
}
.table th,
.table tr,
.table td,
.table thead,
.table tbody,
.table p,
.table h4,
  .table h5{
  font-family: "Poppins", sans-serif;
  color: #22222287;
}
.table h5,
.table h4{
  line-height: 25px!important;
}
.invoice_details_title{
  display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
}
.invoice_details_title p{
font-family: "Poppins",sans-serif;}
.invoice_details_desc{
  margin-left: 10px;
}
.text_bold{
  font-weight: 500;
  
}
.list li{
  font-size: 14px;
  color: #1a1a1a;
  font-family: "Poppins",sans-serif;
}
@media print {
  .order_d_inner {
      display: flex;
      justify-content: space-between;
  }
}
.invoice_columns{
  flex-basis: 50%;
  @media (max-width:767px) {
    flex-basis: 100%;

  padding-bottom: 50px;
  }}
.order_details{
  padding-bottom: 100px;
}

.custom_modal .modal-content{
  height: 300px;
}
.verify_btn{
  border-radius: 10px;
  padding: 0 20px;
  line-height: 40px;
  border: none;
}
.close_btn{
  background: red!important;
}
.modal-header{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.modal-header h4{
  margin-bottom: 0;
  width: 100%;
  margin-left: 8%;
  @media (max-width:400px) {
  margin-left: 0;
    
  }
}
.custom_header{
  border: none;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.custom_body{
  border: none;
  display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-block-start: 50px;
    padding-block-end: 50px;
}
.custom_footer{
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

}