.otp {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    align-items: center;
  }
  .verifyDiv {
    padding: 18px;
  }

  .inputStyle {
    width: 50px !important;
    height: 50px;
    border-radius: 7px;
    border: 0px;
    margin-left: 8px;
    margin-right: 8px;
    background: #fff;
    border: 2px solid rgba(34, 34, 34, 0.5);
    font-size: 20px;
    @media (max-width:500px) {
        width: 40px!important;
    height: 40px;
    }
  }
  .p3 {
    font-size: 14px;
  }
  .resend {
    text-decoration: underline;
    font-size: 14px;
  }
  .otpElements {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 20px;
    margin-bottom: 50px;
    padding-left: 18px;
    justify-content: center;
  }
  

  